import { filter, get, has, isEmpty, omit, pick, isEqual, isUndefined, lte } from 'lodash';
import { useSelector } from 'react-redux';
import { call, takeEvery, takeLatest, takeLeading } from 'redux-saga/effects';
import { handleActions, combineActions } from 'redux-actions';
import { sanitize } from '@ivymobi/orbit-domain';
import { AnyAction } from 'redux';

import {
  createPromiseAction,
  dispatch,
  rejectPromiseAction, resolvePromiseAction,
} from '../util/redux-saga-promise';

import { ContextUser, Tenant, TenantActionMeta, Document } from '../types';


export const useOutLibDocuments = ({user: {user}, tenant}: TenantActionMeta) => useSelector(
  (state) =>{
      return get(state, [user.id, 'tenants', tenant.id, 'outLibDocs'], {})
  }
);

//对外资料添加文件夹
export const postAddFolderAction = createPromiseAction(
  'document/ADD_OUT_LIB_FOLDER',
  (data: {parentid?: string,name?:string,thumbnail?:string }) => pick(data, ['parentid','name','thumbnail']),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
function* watchOutlibAddFolder(api) {
  yield takeEvery(postAddFolderAction, function* (action) {
    try {
      const {payload, meta: {user: {token, user}, tenant}} = action;

      const url = `tenants/${tenant.id}/createExtDocuments`
      const res = yield call(api.postJSON, { 
        url,
        token,
        body:payload
      });

      yield call(resolvePromiseAction, action, res, {user, tenant});
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}



//修改对外资料信息
export const postAlterFolderAction = createPromiseAction(
  'document/Alter_OUT_LIB_FOLDER',
  (data: {id?: string,name?:string,thumbnail?:string }) => pick(data, ['id','name','thumbnail']),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
function* watchOutlibAlterFolder(api) {
  yield takeEvery(postAlterFolderAction, function* (action) {
    try {
      const {payload, meta: {user: {token, user}, tenant}} = action;
      let url='';

      if(payload.name){
        url = `tenants/${tenant.id}/updateName/${payload.id}`
      }else if(payload.thumbnail){
        url = `tenants/${tenant.id}/updateThumbnail/${payload.id}`
      }
      // const url = `tenants/${tenant.id}/updateName`
      const res = yield call(api.postJSON, { 
        url,
        token,
        body:payload
      });

      yield call(resolvePromiseAction, action, res, {user, tenant});
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
//获取对外资料库
export const getOutLibDocumentAction = createPromiseAction(
  'document/GET_OUT_LIB',
  (data: {id?: string }) => pick(data, ['id']),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
function* watchGetOutLibDocument(api) {
  yield takeEvery(getOutLibDocumentAction, function* (action) {
    try {
      const {payload: {id}, meta: {user: {token, user}, tenant}} = action;
      console.log("id===========================",action)
      const url = isEmpty(id)|| id.length==0 ? 
      `tenants/${tenant.id}/Extdocuments`
        :
          `tenants/${tenant.id}/Extdocuments?parentid=${id}` ;
      const res = yield call(api.getJSON, { 
        url,
        token
      });
      let docs = null;
      if (res.type==='catalog'||res.type==='product') {
        docs= res;
      } else {
        docs = yield sanitize.documentList(res);
      }
      yield call(resolvePromiseAction, action, docs, {user, tenant});
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
export const getOutLibDocumentListByIds = createPromiseAction(
  'document/listOutLibByIds',
  (data) => pick(data, ['ids', 'user', 'tenant']),
  (data) => pick(data, ['user', 'tenant'])
);

function* watchGetOutLibDocumentListByIds() {
  yield takeLatest<AnyAction>(getOutLibDocumentListByIds, function* (action) {
    try {
      const { ids, user, tenant } = action.payload;
      for (const id of ids) {
        yield dispatch(getOutLibDocumentAction, { id, user, tenant });
      }
      yield resolvePromiseAction(action);
    } catch (e) {
      yield rejectPromiseAction(action, e);
    }
  });
}
export const reducer = handleActions({

  [combineActions(getOutLibDocumentAction.resolved.toString())]: (state, action) => {
    const docs = [].concat(action.payload).reduce((prev, doc) => {
      prev[doc.type==='catalog'?doc.cgId:(doc.type==='product'?doc.details.supplierAltPid:doc.id)] = doc;
      return prev;
    }, {});
    state = {... state, ...docs}
    return docs;
  }
}, {});



export const watchers = [

watchGetOutLibDocument,watchGetOutLibDocumentListByIds,watchOutlibAddFolder,watchOutlibAlterFolder

];
